

















import { Component, Prop, Vue } from 'vue-property-decorator';
import VideoPlayerSource from '@/app_code/VideoPlayerSource';
import { EmbeddedContent } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

@Component
export default class VideoBannerExternal extends Vue {
  @Prop({ required: true })
  private readonly embeddable!: EmbeddedContent;

  @Prop({ default: false })
  private readonly radius!: boolean;

  @Prop({ default: false })
  private readonly autoPlay!: boolean;

  @Prop({ default: '0.625' })
  private readonly ratio!: string;

  private readonly origin: string = window.location.origin;
}
